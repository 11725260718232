import Vue from 'vue/dist/vue';
import store from '@scripts/store';
import { mapActions } from 'vuex';
import { getFromBinding } from '@scripts/helpers';
import { apiInvoiceStatus, apiInvoiceRegenerate, apiOrdersCancel, apiChangeOrderPaymentMethod } from '@scripts/api-methods';
import { VUEX_ACTIONS, MESSAGES } from '@scripts/constants';
import { redirect } from '@library/scripts/network';
import { showSuccessMessage, showWarningMessage, showErrorMessage } from '@library/uikit/notifications';

export default (el, name) =>
    new Vue({
        el,
        name,
        store,
        data: {
            billsLoading: [],
            selectPaymentMethod: {
                number: 'S0',
                method: ''
            },
        },
        methods: {
            ...mapActions([VUEX_ACTIONS.REPEAT_ORDER]),

            handleRepeatButtonClick(number) {
                this.repeatOrder(number).then(() => {
                    showSuccessMessage({ text: 'Товары добавлены в корзину' });
                    redirect(getFromBinding('routes.cart'), 500);
                });
            },

            isLoaderShown(number) {
                return this.billsLoading.includes(number);
            },

            cancelOrder(number) {
                apiOrdersCancel({
                    order_number: number
                }).then(() => {
                    showSuccessMessage({
                        text: 'Заказ №'+number+' отменен',
                    });
                    window.location.reload();
                })
                .catch(() => {
                    showErrorMessage({
                        text: 'Не удалось отменить заказ',
                    });
                });

                console.log(number);
            },

            //изменеие способа оплаты
            changePaymentMethod(number, paymentMethod){
                if( number === 'S0' || paymentMethod === ''){
                    showErrorMessage({
                        text: 'Не удалось изменить способ оплаты',
                    });
                    return 0;
                }

                apiChangeOrderPaymentMethod({
                    order_number: number,
                    payment_method: paymentMethod
                }).then( (data) => {
                    let msg = (data.msg.length > 1)?data.msg:'Способ оплаты изменен';

                    if (data.type === 'warning'){
                        showWarningMessage({
                            text: msg,
                        })
                    }else{
                        showSuccessMessage({
                            text: msg,
                        });
                        if (data.link.length > 1) {
                            window.location = data.link;
                        }else{
                            window.location.reload();
                        }
                    }

                })
                .catch(() => {
                    showErrorMessage({
                        text: 'Не удалось изменить способ оплаты',
                    });
                });
            },
            updatePaymentLink(number, method) {
                this.changePaymentMethod(number || 'S0', method ||'');
            },
            async downloadBill(number, isRegenerating = false) {
                if (!this.isLoaderShown(number)) this.billsLoading.push(number);

                try {
                    const result = await apiInvoiceStatus({ order_number: number });

                    if (result.status.id === 'ready') {
                        this.billsLoading = this.billsLoading.filter((item) => item !== number);
                        window.location.assign(result.download_url);
                    } else if (result.status.id === 'generating') {
                        setTimeout(() => this.downloadBill(number), 3000);
                    } else if (isRegenerating) {
                        showErrorMessage({
                            text: MESSAGES.BILL_LOAD_ERROR,
                        });

                        this.billsLoading = this.billsLoading.filter((item) => item !== number);
                    } else {
                        await apiInvoiceRegenerate({ order_number: number });
                        await this.downloadBill(number, true);
                    }
                } catch (error) {
                    this.billsLoading = this.billsLoading.filter((item) => item !== number);

                    showErrorMessage({
                        text: MESSAGES.BILL_LOAD_ERROR,
                    });
                }
            },
        },
        watch: {
            'selectPaymentMethod' : function (val) {
                this.changePaymentMethod(val.number || 'S0', val.method ||'');
            }
        },
    });
